import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import Swal from "sweetalert2";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import { useForm } from "react-hook-form";
import { confirmPasswordValidation, emailValidation, passwordValidation } from "../../../utils/Input";
import $ from 'jquery';
import { useLocation } from "react-router-dom";



export default function CreateCP(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired} = props;

    useEffect(() => {document.title = "Create CP | Ringo"}, []);

    useEffect(() => {
        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };
        Promise.all([
        //   loadScript('../assets/libs_/js/jquery.min.js'),
          loadScript('../assets/libs_/js/scripts2.js'),
        ])
          .then(() => {
            // Both scripts are loaded
          })
          .catch((error) => {
            console.error('Error loading scripts:', error);
          });
        return () => {
          // Remove the scripts here if necessary
        };
    }, []);


    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const { register: register1, setValue: setValue1, getValues: getValues1, reset: reset1, handleSubmit: handleSubmit1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({mode: 'onChange'});


    let cp_details = localStorage.getItem('cp_details');
    cp_details = JSON.parse(cp_details);

    const location = useLocation();
    const locationUrl = location.pathname.split("/")[2];
    let cpID = "";
    if(locationUrl){
        cpID = locationUrl.split("--")[1].slice(3, -3);
    }

    let urls = API_ROUTES.CREATE_CP;
    if(cpID && cpID.trim() !== ""){
        urls = API_ROUTES.EDIT_CP;
    }

    useEffect(() => {
      if(location.pathname === "/create-cp"){
        localStorage.removeItem('cp_details');
        setValue1("name", "");
        setValue1("email", "");
        setValue1("id", "");
      }
    }, [location.pathname]);



    const createCPs = async (data) => {
        try {
            const response = await fetch(urls, {
                method: "POST",
                body: objectToFormData(data),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });

            if(responseJSON.status.toString() === "200"){
                Swal.fire({
                    title: "Successful",
                    text: `A CP has been ${!cpID ? "created" : "updated"}!`,
                    icon: "success",
                    timer: 4000
                });
                
                cp_details.name = data.name;
                cp_details.email = data.email;
                localStorage.setItem('cp_details', JSON.stringify(cp_details));

                if(!cpID){
                    setTimeout(() => {
                        reset1();
                    }, 500);
                }
            }else{
                if(responseJSON.status.toString() === "500"){
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.required_fields,
                        icon: "error",
                    });
                    return;
                }
                Swal.fire({
                    title: "Error",
                    text: responseJSON.message,
                    icon: "error",
                });
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }

    


    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt-5">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90 mt-20">
                                    <div className="row g-4 pl-15 pl-xs-0">
                                        <div className="col-lg-10 col-sm-11 p-xs-0"> 
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3>Create CP</h3>
                                                </div>
                                                <div className="card-body formInputs">
                                                    <div className="horizontal-wizard-wrapper vertical-options">
                                                        <div className="row">
                                                            <div className="col-md-12 pl-xs-5 pr-xs-5">
                                                                <div className="tab-content pt-20 pl-10 pr-5 pl-xs-0 pr-xs-0 pb-20" id="vertical-wizard-tabContent_">
                                                                    <div className="tab-pane active" id="change-passwords" role="tabpanel" aria-labelledby="change-passwords-tab">
                                                                        <form className="row" onSubmit={handleSubmit1(createCPs)}>
                                                                            <div className="card-body p-10 pb-20">
                                                                                <div className="row">
                                                                                    
                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="name">CP Name</label>
                                                                                        {errors1.name && <label className="errors">{errors1.name?.message}</label>}

                                                                                        <input className="form-control" id="name" name="name" type="text" placeholder="Enter CP Name"
                                                                                        defaultValue={cp_details ? cp_details.name : ''}
                                                                                        style={{ textTransform:'capitalize' }}
                                                                                        {...register1("name", { 
                                                                                                required: "Enter CP name"
                                                                                            })}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="email">CP Email</label>
                                                                                        {errors1.email && <label className="errors">{errors1.email?.message}</label>}

                                                                                        <input className="form-control" id="email" name="email" type="text" placeholder="Enter CP Email"
                                                                                        defaultValue={cp_details ? cp_details.email : ''}
                                                                                        {...register1("email", {
                                                                                            required: 'Your email address is required',
                                                                                            pattern: {
                                                                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                                                message: 'Invalid email address',
                                                                                            }
                                                                                        })}
                                                                                        />

                                                                                        <input className="form-control" id="id" name="id" type="hidden"
                                                                                        defaultValue={cp_details ? cp_details.id : ''}
                                                                                        {...register1("id")}
                                                                                        />
                                                                                    </div>

                                                                                    {
                                                                                        !cpID &&
                                                                                        <>
                                                                                            <div className="col-md-6 mb-15">
                                                                                                <label className="form-label" htmlFor="password">CP Password</label>
                                                                                                {errors1.password && <label className="errors">{errors1.password?.message}</label>}

                                                                                                <input className="form-control" id="password" type="password" name="password" 
                                                                                                placeholder="Enter Password" 
                                                                                                {...register1("password", {
                                                                                                    required: 'Your password is required',
                                                                                                    minLength: {
                                                                                                        value: 6,
                                                                                                        message: 'Password must be at least 6 characters long'
                                                                                                    },
                                                                                                })}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="col-md-6 mb-15">
                                                                                                <label className="form-label" htmlFor="confirm_password">Confirm CP Password</label>
                                                                                                {errors1.confirm_password && <label className="errors">{errors1.confirm_password?.message}</label>}

                                                                                                <input className="form-control" id="confirm_password" type="password" name="confirm_password" 
                                                                                                placeholder="Confirm Password" 
                                                                                                {...register1("confirm_password", {
                                                                                                    required: 'Confirm password is required',
                                                                                                    validate: (value) => {
                                                                                                        const new_pin = getValues1("password");
                                                                                                        return value === new_pin ? true : "The passwords do not match";
                                                                                                    },
                                                                                                })}
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                    
                                                                                <div className="row mt-20">
                                                                                    <div className="offset-md-6 col-md-6 text-end pr-10">
                                                                                        <button className="btn btn-primary btn-primaryi" type="submit" disabled={isSubmitting1 || !isValid1} style={{ opacity: isSubmitting1 ? '0.5' : 1 }}>{cpID && cpID.trim() === "" ? "Create" : "Edit"} CP </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}