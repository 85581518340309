import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import { format } from "date-fns";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
// import RevenuesTable from "./RevenuesTable";
import Swal from "sweetalert2";
import RevenuesTable from "./RevenuesTable";


export default function Revenues(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired, today, resetTimeToMidnight, resetTimeToB4Midnight, formatDate, allCPs, isLoading1 } = props;

    useEffect(() => {document.title = "Revenue | Ringo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [dataCount, setDataCount] = useState(0);
    const [dataRevenue, setDataRevenue] = useState([]);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [isFade, setIsFade] = useState(true);
    const [cpSelected, setCpSelected] = useState('');
    const [registerName, setRegisterName] = useState('service');
    const clearInputs = useRef(null);


    const columns1 = [
        {   
            name: 'Counts',
            selector: row => row.count ? row.count.toLocaleString() : 0,
            sortable: true,
        },
        {
            name: 'Total Revenue',
            selector: row => row.totalRevenue ? "₦" + row.totalRevenue.toLocaleString() : "₦0",
            sortable: true,
        },
    ];


    useEffect(() => {
        setColumns(columns1);
    }, []);

    const userData = JSON.parse(localStorage.getItem('userDataFs'));


    const getData = async (myFilters={}) => {
        const extractID = myFilters.cp_id.split("|")[0];
        const extractCP = myFilters.cp_id.split("|")[1];
        setCpSelected(extractCP);

        let dataString = {};
        if(myFilters){
            dataString = { 
                cp_id: extractID,
                fro: myFilters.fro,
                to: myFilters.to,
            } 
        }else{
            dataString = { 
                cp_id: extractID,
                fro: resetTimeToMidnight(formatDate(today)),
                to: resetTimeToB4Midnight(formatDate(today)),
            };
        }

        console.log("dataString");
        console.log(dataString);

        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.TOTAL_REVENUE, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            console.log("responseJSON");
            console.log(responseJSON);

            if (responseJSON.status.toString() === "200") {
                
                const responseData = responseJSON.data;

                const arrayData = [responseData];
                // console.log("hereeee");
                // console.log(arrayData);
                setDataRevenue(arrayData);

                setIsLoading(false);
                setIsFade(false);
            }else{
                setDataRevenue(0);
                setIsLoading(false);
                setIsFade(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataRevenue(0);
            setIsLoading(false);
            setIsFade(false);
        }
    }

    // console.log("ssss");
    // console.log(resetTimeToMidnight(formatDate(today)));
    // // console.log(today);


    useEffect(() => {
        getData({ cp_id: '', fro: resetTimeToMidnight(formatDate(today)), to: resetTimeToB4Midnight(formatDate(today)) });
    }, []);

    
    const handleDuration = async (data) => {
        if(data.cp_id === ""){
            Swal.fire({
                title: "Error",
                text: "Please select a CP",
                icon: "error",
            });
            return;
        }
        let dataString = { 
            cp_id: data.cp_id,
            fro: data.fro + " 00:00:00",
            to: data.to + " 23:59:59",
        };
        getData(dataString);
    }



    return (
        <>

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt--20">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90">
                                    <div className="card-header mb--30 mb-xs-10 ml-10">
                                        <h2>Revenue</h2>
                                    </div>
                                    {
                                    isLoading1 ?
                                    <LoadingSpinner />
                                    :
                                    <div className="row g-4">
                                        <div className="col-lg-7 col-md-8 pl-xs-0 pr-xs-0">
                                            <RevenuesTable capitalizeWords={capitalizeWords} dataRevenue={dataRevenue} dataCount={dataCount} isLoading={isLoading} setIsLoading={setIsLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} isFade={isFade} handleDuration={handleDuration} registerName={registerName} clearInputs={clearInputs} isAuthExpired={isAuthExpired} cpSelected={cpSelected} allCPs={allCPs} />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}